<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 直播管理 </template>
      <template #input>
        <a-button class="all_boder_btn" v-hasPermi="['live:info:edit']" @click="toDetail(1)">新建直播</a-button>
      </template>
    </HeaderBox>

    <div class="selects">
      <div class="findItem">
        <a-form-item label="直播时间：" style="display: inline-flex; margin: 0">
          <a-range-picker
            valueFormat="YYYY-MM-DD HH:mm:ss"
            showTime
            v-model="findtime"
            @ok="onLiveTime"
          ></a-range-picker>
        </a-form-item>
        直播名称：<a-input v-model="title" placeholder="请输入直播名称" />
        上架状态：
        <a-select
          style="width: 200px"
          v-model="shelvesStatus"
          placeholder="请选择上架状态"
        >
          <a-select-option :value="0"> 下架 </a-select-option>
          <a-select-option :value="1"> 上架 </a-select-option>
        </a-select>
      </div>
      <div class="findItem">
        <a-button type="primary" @click="search()" v-hasPermi="['live:list:query']">搜索</a-button>
        <a-button class="all_btn_border btnBorder" @click="reset"
          >重置</a-button
        >
      </div>
    </div>

    <a-table
      class="table-template"
      :rowKey="(item) => item.id"
      :columns="columns"
      :data-source="tableData"
      :loading="loading"
      :pagination="{
        total: total,
        showTotal: (res) => `共${total}条`,
        showQuickJumper: true,
        current: current,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '50', '100'],
      }"
      @change="onPage"
    >
      <template slot="index" slot-scope="item, row, index">
        {{ (current - 1) * size + index + 1 }}
      </template>

      <template slot="subscribeDate" slot-scope="item, row">
        {{ row.subscribeStartDate }} - {{ row.subscribeEndDate }}
      </template>

      <template slot="liveDate" slot-scope="item, row">
        {{ row.liveStartDate }} - {{ row.liveEndDate }}
      </template>

      <template slot="remindType" slot-scope="item">
        {{ item == 1 ? "自动触发" : "手动触发" }}
      </template>
      <template slot="remindFlag" slot-scope="item">
        {{ item ? "已发送" : "未发送" }}
      </template>
      <template slot="shelvesStatus" slot-scope="item">
        {{ item == 1 ? "已上架" : "已下架" }}
      </template>

      <!-- 直播若已购买不能下架 -->
      <template slot="operation" slot-scope="item, row">
        <span class="blueText">
          <span
            v-hasPermi="['live:info:edit']"
            v-if="funShow(row.liveEndDate)"
            @click="onOpenUpload(row)"
            >{{row.publishFlag == 1 ? "编辑" : "上传"}}回放</span
          >
          <span v-hasPermi="['live:info:edit']">{{ funShow(row.liveEndDate) ? "|" : "" }}</span>
          <span
            v-if="row.subscribeCount && !row.remindFlag"
            @click="sendLiveInfo(row)" v-hasPermi="['live:message:add']"
            >发送提醒</span
          >
          <span v-hasPermi="['live:message:add']">{{ row.subscribeCount && !row.remindFlag ? "|" : "" }}</span>
          <span @click="toDetail(row.remindFlag ? 3 : 2, row.id)">{{
            row.remindFlag ? "详情" : "编辑"
          }}</span>
          | <span @click="delateFilling(row.id)" v-hasPermi="['live:info:delete']">删除 | </span>
          <span @click="toDetail(4, row.id)">预约名单</span>
          <span @click="onEdit(row, 0)" v-hasPermi="['live:info:edit']" v-if="row.shelvesStatus != 0"
            >  | 下架</span
          >
          <span @click="onEdit(row, 1)" v-hasPermi="['live:info:edit']" v-else>  | 上架</span>
        </span>
      </template>
    </a-table>
    <a-modal
      v-model="over"
      width="300px"
      title="提示"
      :centered="true"
      :closable="false"
    >
      <div class="tips">是否确认删除直播？</div>
      <template slot="footer">
        <a-button type="primary" @click="delateOk">确认</a-button>
        <a-button @click="delateCancel">取消</a-button>
      </template>
    </a-modal>
    <a-modal
      v-model="send"
      width="300px"
      title="提示"
      :centered="true"
      :closable="false"
    >
      <div class="tips">是否确认发送提醒？</div>
      <template slot="footer">
        <a-button type="primary" @click="sendOk">确认</a-button>
        <a-button @click="sendCancel">取消</a-button>
      </template>
    </a-modal>
    <a-modal
      v-model="edit"
      width="530px"
      title="提示信息"
      :centered="true"
      :closable="false"
    >
      <div class="tips">
        该直播已有预约人员，已创建订单或直播中。暂且不可下架。
      </div>
      <template slot="footer">
        <a-button type="primary" @click="editOk">确认</a-button>
      </template>
    </a-modal>
    <a-modal
      v-model="video"
      width="400px"
      title="上传回放"
      :centered="true"
      :closable="false"
    >
      <div class="upload-video">
        <div class="flex-line">
          <span>回放视频：</span>
          <div>
            <a-upload
              name="videoFileId"
              :showUploadList="false"
              :customRequest="customRequest"
            >
              <a-button>
                <template v-if="uploadLoading">
                  <a-icon type="loading" />
                  <span>上传中 {{(percent*100).toFixed(0)}}%</span>
                </template>
                <template v-else>
                  <a-icon type="upload" />
                  <span>选择视频文件</span>
                </template>
              </a-button>
            </a-upload>
            <p v-if="videoFileId">
              <span>上传成功：</span>
              <span>{{videoFileId}}</span>
            </p>
          </div>
        </div>
        <div class="flex-line">
          <span>视频观看限制：</span>
          <div>
            <a-checkbox v-model="checked">仅已预约学员观看</a-checkbox>
          </div>
        </div>
      </div>
      <template slot="footer">
        <a-button type="primary" :loading="confirmLoading" @click="onUploadVideo">确认</a-button>
        <a-button @click="video = false">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
import moment from "moment";
const columns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "直播名称",
    align: "center",
    dataIndex: "title",
  },
  {
    title: "主讲老师",
    align: "center",
    dataIndex: "teacherName",
  },
  {
    title: "预约时间",
    align: "center",
    scopedSlots: {
      customRender: "subscribeDate",
    },
  },
  {
    title: "直播时间",
    align: "center",
    scopedSlots: {
      customRender: "liveDate",
    },
  },
  {
    title: "创建时间",
    align: "center",
    dataIndex: "createTime",
  },
  {
    title: "已预约人数",
    align: "center",
    dataIndex: "subscribeCount",
  },
  {
    title: "状态",
    align: "center",
    dataIndex: "shelvesStatus",
    scopedSlots: { customRender: "shelvesStatus" },
  },
  {
    title: "发送提醒方式",
    align: "center",
    dataIndex: "remindType",
    scopedSlots: { customRender: "remindType" },
  },
  {
    title: "是否已发送提醒",
    align: "center",
    dataIndex: "remindFlag",
    scopedSlots: { customRender: "remindFlag" },
  },
  {
    title: "操作",
    align: "center",
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
  },
];
export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      columns,

      // 筛选项
      title: "", // 直播名称
      shelvesStatus: "", // 直播名称
      liveEndDate: "", // 直播开始时间
      liveStartDate: "", // 直播开始时间
      findtime: ["", ""],

      delId: null, // 要删除id
      sendId: null, // 提醒id
      playId: null, // 回放id

      tableData: [],
      loading: false,
      keyWord: undefined,
      status: "",
      current: 1, //页码
      size: 10, // 页数
      total: 0,
      over: false,
      send: false,
      edit: false,
      video: false,

      percent: 0,
      confirmLoading: false,
      uploadLoading: false,
      videoFileId: 0,
      checked: false
    };
  },
  // 事件处理器
  methods: {
    moment,
    // 重置
    reset() {
      this.title = ""; // 直播名称
      this.liveEndDate = ""; // 直播开始时间
      this.liveStartDate = ""; // 直播开始时间
      this.findtime = ["", ""];
      this.shelvesStatus = "";
      this.current = 1;
      this.getList();
    },

    // 分页
    onPage(e) {
      this.current = e.current;
      this.size = e.pageSize;
      this.getList();
    },

    // 直播时间确认
    onLiveTime() {
      this.liveStartDate = this.findtime[0];
      this.liveEndDate = this.findtime[1];
    },

    onOpenUpload(row){
      if(row.publishFlag == 1){
        this.videoFileId = row.videoFileId
        this.checked = row.bookingPlay ? true : false
      } else if(this.playId != row.id){
        this.videoFileId = ''
        this.checked = false
      }
      this.playId = row.id
      this.video = true
    },


    // 上传回放视频
    onUploadVideo(){
      if(!this.videoFileId){
        this.$message.error('请上传直播回放视频');
        return
      }
      this.confirmLoading = true
      this.$ajax({
        url: "/hxclass-management/liveInfo/saveOrUpdateLiveInfo",
        method: "post",
        params: {
          id: this.playId,
          videoFileId: this.videoFileId,
          publishFlag: 1,
          bookingPlay: this.checked ? 1 : 0
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success("上传成功！");
          this.video = false

          this.current = 1;
          this.getList();
        } else {
          this.$message.error(res.message);
        }
        this.confirmLoading = false;
      });
    },

    // 重置文件上传 - 单文件
    customRequest(fileData){
      this.percent = 0
      this.uploadLoading = true
      this.$upload_video({
        file: fileData.file,
        progress: (e)=>{
          this.$set(this, 'percent', e.percent)
        },
        success: (e)=>{
          this.videoFileId = e
          this.uploadLoading = false
        }
      })
    },

    //  搜索
    search() {
      // console.log(this.title, this.liveStartDate, this.liveEndDate);
      this.current = 1;
      this.getList();
    },

    callback(e) {
      // console.log(e);
      this.status = e;
    },
    //  operation： 1-新建 2-编辑 3-详情 4-预约名单
    toDetail(operation, id) {
      if (operation == 4) {
        this.$router.push(
          "/admin/contentConfig/liveAppointment?" + "id=" + (id ? id : "")
        );
      } else if (operation == 5) {
        this.$router.push(
          "/admin/contentConfig/liveEnd?" + "id=" + (id ? id : "")
        );
      } else {
        this.$router.push(
          "/admin/contentConfig/liveDetail?" +
            "operation=" +
            operation +
            "&id=" +
            (id ? id : "")
        );
      }
    },
    delateFilling(e) {
      this.delId = e;
      this.over = true;
    },
    sendLiveInfo(e) {
      this.sendId = e.id;
      this.send = true;
    },
    delateOk() {
      this.$ajax({
        url: "/hxclass-management/liveInfo/deleteLiveInfo?id=" + this.delId,
        method: "delete",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success("删除成功！");
          this.over = false;
          if (this.current == 1) {
            return this.getList();
          }
          if ((this.current - 1) * this.size == this.total - 1) {
            this.current -= 1;
          }
          this.getList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    delateCancel() {
      this.over = false;
    },
    sendOk() {
      this.$ajax({
        url: "/hxclass-management/liveInfo/sendLiveInfo?id=" + this.sendId,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success("发送成功！");
          this.send = false;
          this.getList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    sendCancel() {
      this.send = false;
    },
    // 上下架
    onEdit(e, r) {
      e.shelvesType = r;
      e.shelvesStatus = r;
      this.$ajax({
        url: "/hxclass-management/liveInfo/saveOrUpdateLiveInfo",
        method: "post",
        params: e,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.getList();
          this.$message.success(res.message);
        } else {
          this.btnLoading = false;
          this.$message.error(res.message);
        }
      });
    },
    editFilling() {
      this.edit = true;
    },
    editOk(e) {
      this.edit = false;
    },
    getList() {
      this.loading = true;
      this.$ajax({
        url: "/hxclass-management/liveInfo/getLiveInfoList",
        params: {
          liveEndDate: this.liveEndDate,
          liveStartDate: this.liveStartDate,
          pageNum: this.current,
          pageSize: this.size,
          title: this.title,
          shelvesStatus: this.shelvesStatus,
        },
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.total = res.data.total;
          this.tableData = res.data.records;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    funShow(d2){
      const d1 = new Date()
      return this.moment(d1).format("YYYY-MM-DD HH:mm:ss") > this.moment(d2).format("YYYY-MM-DD HH:mm:ss")
    }
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getList();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: { },
  // 自定义的侦听器
  watch: {
    status(newval) {
      this.getList(newval);
    },
  },
};
</script>

<style lang="less" scoped>
.all_main_wrap {
  min-height: 100%;
  .btn {
    margin: 0 24px 14px 0;
  }

  .table-box {
    display: flex;
    justify-content: space-between;

    /deep/ .ant-tabs-bar {
      border-bottom: none;
    }

    /deep/.ant-tabs-bar {
      margin: 0;
    }

    .all_boder_btn {
      margin-top: 10px;
    }
  }
}
.selects {
  // width: 929px;
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-items: flex-end;
  justify-content: space-between;
}
.findItem {
  padding-bottom: 24px;
  display: flex;
  align-items: center;
  // /deep/.ant-form-item-control-wrapper{
  //   width: 240px !important;
  //   height: 29px !important;
  // }
  /deep/.ant-input {
    width: 300px !important;
    height: 29px !important;
    font-size: 12px !important;
    color: #999999 !important;
    line-height: 14px !important;
    margin-right: 20px;
    margin-bottom: 0 !important;
  }
  .ant-btn {
    margin-right: 24px;
  }
  .ant-btn:last-child {
    margin-right: 0;
  }
}
/deep/.ant-modal-title {
  color: #000000;
  text-align: center;
}
/deep/.ant-modal-header {
  border-bottom: none;
}
/deep/.ant-modal-footer {
  text-align: center;
  border-top: none;
}
/deep/ .ant-modal-body {
  padding: 14px 24px 14px;
  text-align: center;
}
.tips {
  font-size: 16px;
  color: #000000;
  text-align: center;
}
.upload-video{
  text-align: left;
  font-size: 14px;
  .flex-line{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
}
</style>
